import React, { useEffect } from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"

import Layout from "../components/layout/Layout"

const NotFoundPage = () => {
  useEffect(() => {
    document.documentElement.style.setProperty("--header-bg", "#323137")
    document.documentElement.style.setProperty("--header-text", "#F8F6F2")
  }, [])

  return (
    <Layout title="404: Not found">
      <div className="px-ogs py-12 bg-header-bg text-header-text xl:h-100vh-shh">
        <div className="grid grid-cols-8 gap-gs font-fletcha leading-none tracking-tightest xl:h-full">
          <div className="col-start-1 col-end-9 xl:col-end-5 xl:flex xl:flex-col xl:justify-between">
            <Fade triggerOnce duration={750}>
              <h1 className="text-12xl sm:text-90">404</h1>
            </Fade>
            <Fade
              triggerOnce
              duration={500}
              className="hidden xl:flex mt-12 xl:mt-0 max-w-300 w-fit"
            >
              <Link
                to="/"
                className="Link Link--alt Link--underline text-xl lg:text-3xl font-tomato tracking-tightest"
              >
                Go home
              </Link>
            </Fade>
          </div>
          <div className="col-start-1 col-end-9 xl:col-start-5 xl:flex xl:flex-col xl:justify-between">
            <Fade triggerOnce duration={750}>
              <p className="leading-12 sm:leading-25 text-6xl sm:text-12xl">
                This page does
                <br /> not seem to
                <br /> exist.
              </p>
            </Fade>
            <Fade triggerOnce duration={750}>
              <div className="xl:grid xl:grid-cols-4 xl:gap-gs">
                <p className="col-start-1 col-end-5 text-4xl m:text-5xl leading-12 mt-12">
                  “Whatever it is you're seeking
                  <br className="hidden sm:block" /> won't come in the form
                  <br className="hidden sm:block" /> you're expecting.”
                </p>
                <p className="col-start-2 col-end-5 text-2lg mt-12">
                  ― Haruki Marukami
                </p>
                <Link
                  to="/"
                  className="xl:hidden flex mt-12 xl:mt-0 max-w-300 w-fit border-b hover:border hover:px-9 py-2 lg:py-3 hover:rounded-full text-xl lg:text-3xl font-tomato tracking-tightest"
                >
                  Go home
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
